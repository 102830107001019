import { inject, Injectable, signal } from '@angular/core';
import {
  AcceptFreightAssignationFromMarketCommand,
  AddFreightCollaboratorInterestedCommand,
} from '@okcargo/command-processor';
import {
  CriteriaPayload,
  FilterCombinator,
  FilterComparator,
  FilterPayload,
  FindMarketFreightQuery,
  FreightAssignationType,
  FreightFieldNames,
  FreightStatus,
  OrderPayload,
  OrderType,
  SearchAllMarketFreightsQuery,
} from '@okcargo/query-processor';
import { BehaviorSubject, from, map, Observable, of, switchMap } from 'rxjs';
import { MarketCommandService } from '../infrastructure/market-command.service';
import { MarketQueryService } from '../infrastructure/market-query.service';
import {
  EFreightLoadingType,
  EFreightStatus,
  EFreightVehicleType,
  getEmptyDefaultMarketFreight,
  IAddress,
  IMarketFreight,
  IStopBasic,
} from './../domain/market.model';
// import { MarketCommandService } from '../infrastructure/market-command.service';

@Injectable({
  providedIn: 'root',
})
export class MarketService {
  private readonly marketQueryService = inject(MarketQueryService);
  private readonly marketCommandService = inject(MarketCommandService);

  private readonly page: number = 0;
  private readonly size: number = 200;

  private filters: FilterPayload[] = [];

  private originCountryCode: string | undefined;
  private originStateCode: string | undefined;
  private destinationCountryCode: string | undefined;
  private destinationStateCode: string | undefined;

  private readonly _freightsInMarket$ = new BehaviorSubject<IMarketFreight[]>([]);

  private readonly _refreshMarketDetail$ = new BehaviorSubject<void>(undefined);

  public freightsInMarketLoading = signal(true);

  constructor() {}

  applyFilter(
    originCountryCode: string | undefined,
    originStateCode: string | undefined,
    destinationCountryCode: string | undefined,
    destinationStateCode: string | undefined,
  ) {
    this.filters = [];
    this.originCountryCode = originCountryCode;
    this.originStateCode = originStateCode;
    this.destinationCountryCode = destinationCountryCode;
    this.destinationStateCode = destinationStateCode;
    this.addFilters('origin', this.originCountryCode, this.originStateCode);
    this.addFilters('destination', this.destinationCountryCode, this.destinationStateCode);
    this.refreshFreightsInMarket();
  }

  get filter() {
    return {
      originCountryCode: this.originCountryCode,
      originStateCode: this.originStateCode,
      destinationCountryCode: this.destinationCountryCode,
      destinationStateCode: this.destinationStateCode,
    } as any;
  }

  private addFilters(type: string, countryCode: string | undefined, stateCode: string | undefined) {
    if (countryCode && stateCode) {
      this.filters.push({
        combinator: this.filters.length === 0 ? FilterCombinator.NONE : FilterCombinator.AND,
        field: type === 'origin' ? FreightFieldNames.RAW_FIRST_STOP : FreightFieldNames.RAW_LAST_STOP,
        comparator: FilterComparator.LIKE,
        value: '%"countryCode":"' + countryCode + '"%',
      } as FilterPayload);
      this.filters.push({
        combinator: FilterCombinator.AND,
        field: type === 'origin' ? FreightFieldNames.RAW_FIRST_STOP : FreightFieldNames.RAW_LAST_STOP,
        comparator: FilterComparator.LIKE,
        value: '%"stateCode":"' + stateCode + '"%',
      } as FilterPayload);
    }
  }

  get freightsInMarket$(): BehaviorSubject<IMarketFreight[]> {
    return this._freightsInMarket$;
  }

  get refreshMarketDetail$(): Observable<void> {
    return this._refreshMarketDetail$.asObservable();
  }

  public refreshFreightsInMarket(): void {
    this.searchFreightsInMarket();
  }

  /*public refreshAllFreightsInMarket(_filters: FilterPayload[]): void {
    this.searchAllFreightsInMarket(_filters);
  }*/

  public refreshMarketDetail(): void {
    this._refreshMarketDetail$.next();
  }

  private searchFreightsInMarket(): void {
    const query = {
      criteriaPayload: {
        order: [
          {
            field: FreightFieldNames.STARTING_AT,
            type: this.filters.length > 0 ? OrderType.DESC : OrderType.ASC,
          } as OrderPayload,
        ],
        filters:
          this.filters.length > 0
            ? this.filters
            : [
                {
                  combinator: FilterCombinator.NONE,
                  field: FreightFieldNames.SHIPPER_CARRIER_ASSIGNATION_TYPE,
                  comparator: FilterComparator.EQ,
                  value: FreightAssignationType.MARKET,
                } as FilterPayload,
              ],
        pageIndex: this.page,
        pageSize: this.size,
      } as unknown as CriteriaPayload,
    } as SearchAllMarketFreightsQuery;

    from(this.marketQueryService.searchAllMarketFreights(query))
      .pipe(
        map((freights) =>
          freights
            .map((marketFreightResponse): IMarketFreight => {
              const freight: IMarketFreight = {
                id: marketFreightResponse.id,
                journeyId: marketFreightResponse.journeyId,
                numberOfStops: marketFreightResponse.numberOfStops,
                reference: marketFreightResponse.carrierFreightM2mReference,
                price: marketFreightResponse.price,
                stops: this.transformToIStop(marketFreightResponse.stops),
                status: this.transformToEFreightStatus(marketFreightResponse.status), // calculate this
                carrier: {
                  id: marketFreightResponse.carrierId,
                  name: marketFreightResponse.carrierName,
                  phone: marketFreightResponse.carrierPhone,
                  taxId: marketFreightResponse.carrierTaxId,
                },
                deliveryNoteInPaper: false,
                instructions: marketFreightResponse.instructions,
                loadingTypes: marketFreightResponse.loadingTypes.map(
                  (vehicle) => vehicle as unknown as EFreightLoadingType,
                ), // marketFreightResponse.loadingTypes,
                vehicleTypes: marketFreightResponse.vehicleTypes.map(
                  (vehicle) => vehicle as unknown as EFreightVehicleType,
                ), // marketFreightResponse.vehicleTypes,
                nature: marketFreightResponse.nature,
                packages: marketFreightResponse.packages,
                weight: marketFreightResponse.weight,
                title: 'Carga ' + marketFreightResponse.carrierFreightM2mReference,
                assignationId: marketFreightResponse.carrierCollaboratorAssignationId,
                isAssigned: marketFreightResponse.isAssigned,
              };
              return freight; // this.transformToIMarketFreight(marketFreightResponse, freight);
            })
            .sort((a, b) => {
              return a.isAssigned === b.isAssigned ? 0 : a.isAssigned ? 1 : -1;
            }),
        ),
      )
      .subscribe((transformedFreights) => {
        this._freightsInMarket$.next(transformedFreights);
        this.freightsInMarketLoading.set(false);
      });
  }

  /*private searchAllFreightsInMarket(_filters: FilterPayload[]): void {
    const query = {
      criteriaPayload: {
        order: [
          {
            field: FreightFieldNames.STARTING_AT,
            type: OrderType.ASC,
          } as OrderPayload,
        ],
        filters: _filters,
        pageIndex: this.page,
        pageSize: this.size,
      } as unknown as CriteriaPayload,
    } as SearchAllMarketFreightsQuery;

    from(this.marketQueryService.searchAllMarketFreights(query))
      .pipe(
        map((freights) =>
          freights.map((marketFreightResponse): IMarketFreight => {
            const freight: IMarketFreight = {
              id: marketFreightResponse.id,
              journeyId: marketFreightResponse.journeyId,
              numberOfStops: marketFreightResponse.numberOfStops,
              reference: marketFreightResponse.carrierFreightM2mReference,
              price: marketFreightResponse.price,
              stops: this.transformToIStop(marketFreightResponse.stops),
              status: this.transformToEFreightStatus(marketFreightResponse.status), // calculate this
              carrier: {
                id: marketFreightResponse.carrierId,
                name: marketFreightResponse.carrierName,
                phone: marketFreightResponse.carrierPhone,
                taxId: marketFreightResponse.carrierTaxId,
              },
              deliveryNoteInPaper: false,
              instructions: marketFreightResponse.instructions,
              loadingTypes: marketFreightResponse.loadingTypes.map(
                (vehicle) => vehicle as unknown as EFreightLoadingType,
              ), // marketFreightResponse.loadingTypes,
              vehicleTypes: marketFreightResponse.vehicleTypes.map(
                (vehicle) => vehicle as unknown as EFreightVehicleType,
              ), // marketFreightResponse.vehicleTypes,
              nature: marketFreightResponse.nature,
              packages: marketFreightResponse.packages,
              weight: marketFreightResponse.weight,
              title: 'Carga ' + marketFreightResponse.carrierFreightM2mReference,
              assignationId: marketFreightResponse.carrierCollaboratorAssignationId,
              isAssigned: marketFreightResponse.isAssigned,
            };
            return freight; // this.transformToIMarketFreight(marketFreightResponse, freight);
          }),
        ),
      )
      .subscribe((transformedFreights) => {
        this._freightsInMarket$.next(transformedFreights);
        this.freightsInMarketLoading.set(false);
      });
  }*/

  public findMarketFreightDetail$(id: string): Observable<IMarketFreight> {
    const query = {
      id: id,
    } as FindMarketFreightQuery;

    return from(this.marketQueryService.findFreightFromMarket(query)).pipe(
      switchMap((marketFreightDetailedResponse): Observable<IMarketFreight> => {
        if (!marketFreightDetailedResponse || typeof marketFreightDetailedResponse === 'string') {
          // console.warn('Freight vacío');
          const freight = getEmptyDefaultMarketFreight();
          freight.status = EFreightStatus.ERROR;
          return of(freight);
        }

        const transformedFreight: IMarketFreight = {
          id: marketFreightDetailedResponse.id,
          numberOfStops: marketFreightDetailedResponse.numberOfStops,
          reference: marketFreightDetailedResponse.carrierFreightM2mReference,
          price: marketFreightDetailedResponse.price,
          stops: this.transformToIStop(marketFreightDetailedResponse.stops),
          status: this.transformToEFreightStatus(marketFreightDetailedResponse.status), // calculate this
          carrier: {
            id: marketFreightDetailedResponse.carrierId,
            name: marketFreightDetailedResponse.carrierName,
            phone: marketFreightDetailedResponse.carrierPhone,
            taxId: marketFreightDetailedResponse.carrierTaxId,
          },
          deliveryNoteInPaper: false,
          instructions: marketFreightDetailedResponse.instructions,
          loadingTypes: marketFreightDetailedResponse.loadingTypes.map(
            (vehicle) => vehicle as unknown as EFreightLoadingType,
          ), // marketFreightResponse.loadingTypes,
          nature: marketFreightDetailedResponse.nature,
          packages: marketFreightDetailedResponse.packages,
          vehicleTypes: marketFreightDetailedResponse.vehicleTypes.map(
            (vehicle) => vehicle as unknown as EFreightVehicleType,
          ), // marketFreightResponse.vehicleTypes,
          weight: marketFreightDetailedResponse.weight,
          title: marketFreightDetailedResponse.carrierFreightM2mReference,
          assignationId: marketFreightDetailedResponse.carrierCollaboratorAssignationId,
          isAssigned: marketFreightDetailedResponse.isAssigned,
          journeyId: marketFreightDetailedResponse.journeyId,
        };

        //return freight; // this.transformToIMarketFreight(marketFreightResponse, freight);
        return of(transformedFreight);
      }),
    );
  }

  public async acceptFreightAssignationFromMarket(id: string, assignationId: string): Promise<void> {
    this.marketCommandService
      .acceptFreightAssignationFromMarket({
        assignationId: assignationId,
        parentAssignationId: null as unknown as string,
        freightId: id,
      } as AcceptFreightAssignationFromMarketCommand)
      .then(
        () => {},
        async (error: any) => {
          // TODO implement this
          console.warn(error);
        },
      );
  }

  public async showInterestOnFreight(_id: string): Promise<void> {
    this.marketCommandService
      .addFreightCollaboratorInterested({
        id: _id,
        comments: '',
      } as AddFreightCollaboratorInterestedCommand)
      .then(
        () => {},
        async (error: any) => {
          // TODO implement this
          console.warn(error);
        },
      );
  }

  // TODO mapper, move
  private transformToIStop(stops: any[]): IStopBasic[] {
    return stops.map((stop: any) => {
      return {
        id: stop.id,
        address: {
          city: stop.city,
          postalCode: stop.postalCode,
          state: stop.state,
          country: stop.country,
        } as IAddress,
        position: +stop.position, // number
        since: new Date(stop.since),
        until: new Date(stop.until),
        status: stop.status,
        type: stop.type,
      } as IStopBasic;
    });
  }

  // TODO review, use freight service (the exported function?); otherwise, move
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  private transformToEFreightStatus(freightStatusResponse: FreightStatus) {
    switch (freightStatusResponse) {
      case FreightStatus.OFFER_REJECTED:
      case FreightStatus.PENDING:
        return EFreightStatus.PENDING;

      case FreightStatus.OFFER_REQUESTED: // ??? seen in freights in progress
      case FreightStatus.OFFER_SENT: // ??? seen in freights in progress
      case FreightStatus.ASSIGNED:
      case FreightStatus.ACCEPTED:
        return EFreightStatus.ASSIGNED;

      case FreightStatus.STARTED:
      case FreightStatus.ARRIVED:
      case FreightStatus.WORKING_START:
      case FreightStatus.WORKING_END:
      case FreightStatus.ON_ROUTE:
        return EFreightStatus.ON_ROUTE;

      case FreightStatus.FINISHED:
      case FreightStatus.FINISHED_BY_CARRIER:
      case FreightStatus.FINISHED_BY_SHIPPER:
      case FreightStatus.FINISHED_WITH_ISSUE:
      case FreightStatus.FINISHED_WITH_ISSUE_SOLVED:
        return EFreightStatus.FINISHED;

      case FreightStatus.CANCELLED_BY_CARRIER:
      case FreightStatus.CANCELLED_BY_SHIPPER:
        return EFreightStatus.CANCELLED;

      case FreightStatus.REJECTED:
      case FreightStatus.NO_SHOW_BY_CARRIER:
      case FreightStatus.NO_SHOW_BY_SHIPPER:
      case FreightStatus.DELETED:
      default:
        return EFreightStatus.DELETED;
    }
  }
}
