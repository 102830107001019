import { Injectable } from '@angular/core';
import {
  AcceptFreightAssignationFromMarketCommand,
  AcceptFreightAssignationFromMarketCommandHandler,
  AddFreightCollaboratorInterestedCommand,
  AddFreightCollaboratorInterestedCommandHandler,
} from '@okcargo/command-processor';
import { BaseCommandRepository } from 'src/app/common/infrastructure/command.repository';

@Injectable({ providedIn: 'root' })
export class MarketCommandService {
  private readonly acceptFreightAssignationFromMarketCommandHandler: AcceptFreightAssignationFromMarketCommandHandler;
  private readonly addFreightCollaboratorInterestedCommandHandler: AddFreightCollaboratorInterestedCommandHandler;

  constructor(private readonly commandRepository: BaseCommandRepository) {
    this.acceptFreightAssignationFromMarketCommandHandler = new AcceptFreightAssignationFromMarketCommandHandler(
      commandRepository,
    );
    this.addFreightCollaboratorInterestedCommandHandler = new AddFreightCollaboratorInterestedCommandHandler(
      commandRepository,
    );
  }

  public acceptFreightAssignationFromMarket(command: AcceptFreightAssignationFromMarketCommand): Promise<void> {
    return this.acceptFreightAssignationFromMarketCommandHandler.handle(command);
  }

  public addFreightCollaboratorInterested(command: AddFreightCollaboratorInterestedCommand): Promise<void> {
    return this.addFreightCollaboratorInterestedCommandHandler.handle(command);
  }
}
