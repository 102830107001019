<ion-list class="ion-hide-md-down">
  <ion-menu-toggle autoHide="false">
    <ion-item
      button
      routerLink="/tabs/dashboard"
      routerLinkActive="active"
      routerDirection="root"
      detail="false"
    >
      <ion-icon
        aria-hidden="true"
        src="../assets/material-symbols/swap_driving_apps_wheel_FILL0_wght400_GRAD0_opsz24.svg"
        slot="start"
      ></ion-icon>
      <ion-label>{{ 'general.nav_bar.tasks' | translate }}</ion-label>
    </ion-item>
  </ion-menu-toggle>
  <ion-menu-toggle autoHide="false">
    <ion-item
      button
      routerLink="/tabs/freight-inprogress"
      routerLinkActive="active"
      routerDirection="root"
      detail="false"
    >
      <ion-icon
        aria-hidden="true"
        src="../assets/material-symbols/okcargo-freight-inprogress.svg"
        slot="start"
      ></ion-icon>
      <ion-label>{{ 'general.nav_bar.freights' | translate }}</ion-label>
    </ion-item>
  </ion-menu-toggle>
  <ion-menu-toggle autoHide="false">
    <ion-item
      button
      routerLink="/tabs/freight-finished"
      routerLinkActive="active"
      routerDirection="root"
      detail="false"
    >
      <ion-icon
        aria-hidden="true"
        src="../assets/material-symbols/local_shipping_FILL0_wght400_GRAD0_opsz24.svg"
        slot="start"
      ></ion-icon>
      <ion-label>{{ 'general.nav_bar.finished' | translate }}</ion-label>
    </ion-item>
  </ion-menu-toggle>

  @if ((userCapabilities$ | async)?.includes(ECapabilities.VIEW_MARKET)) {
    <ion-menu-toggle autoHide="false">
      <ion-item
        button
        routerLink="/tabs/market"
        routerLinkActive="active"
        routerDirection="root"
        detail="false"
      >
        <ion-icon
          aria-hidden="true"
          src="../assets/material-symbols/storefront_FILL0_wght400_GRAD0_opsz24.svg"
          slot="start"
        ></ion-icon>
        <ion-label>{{ 'general.nav_bar.market' | translate }}</ion-label>
      </ion-item>
    </ion-menu-toggle>
  }
</ion-list>

<ion-list>
  @if ((userCapabilities$ | async)?.includes(ECapabilities.ADMINISTRATIVE_DOCUMENTS)) {
    <ion-menu-toggle autoHide="false">
      <ion-item
        button
        routerLink="/my-company"
        routerLinkActive="active"
        routerDirection="root"
        detail="false"
      >
        <ion-icon
          aria-hidden="true"
          src="../assets/material-symbols/domain_24dp_FILL0_wght400_GRAD0_opsz24.svg"
          slot="start"
        ></ion-icon>
        <ion-label>{{ 'company.my_company' | translate }}</ion-label>
      </ion-item>
    </ion-menu-toggle>
  }
  <!-- @if ((userCapabilities$ | async)?.includes(ECapabilities.UPLOAD_FREIGHTS)) {
    <ion-menu-toggle autoHide="false">
      <ion-item
        button
        routerLink="/posted-freight"
        routerLinkActive="active"
        routerDirection="root"
        detail="false"
      >
        <ion-icon
          aria-hidden="true"
          src="../assets/material-symbols/okcargo-upload-freights.svg"
          slot="start"
        ></ion-icon>
        <ion-label>{{ 'freight.post.published_freights' | translate }}</ion-label>
      </ion-item>
    </ion-menu-toggle>
  } -->
  @if ((userCapabilities$ | async)?.includes(ECapabilities.CRUD_VEHICLES)) {
    <ion-menu-toggle autoHide="false">
      <ion-item
        button
        routerLink="/vehicles"
        routerLinkActive="active"
        routerDirection="root"
        detail="false"
      >
        <ion-icon
          aria-hidden="true"
          src="../assets/material-symbols/garage_24dp_FILL0_wght400_GRAD0_opsz24.svg"
          slot="start"
        ></ion-icon>
        <ion-label>{{ 'general.nav_bar.vehicles' | translate }}</ion-label>
      </ion-item>
    </ion-menu-toggle>
  }

  @if ((userCapabilities$ | async)?.includes(ECapabilities.CRUD_USERS)) {
    <ion-menu-toggle autoHide="false">
      <ion-item
        button
        routerLink="/users"
        routerLinkActive="active"
        routerDirection="root"
        detail="false"
      >
        <ion-icon
          aria-hidden="true"
          src="../assets/material-symbols/group_24dp_FILL0_wght400_GRAD0_opsz24.svg"
          slot="start"
        ></ion-icon>
        <ion-label>{{ 'general.nav_bar.users' | translate }}</ion-label>
      </ion-item>
    </ion-menu-toggle>
  }
</ion-list>

<ion-list>
  <ion-menu-toggle autoHide="false">
    <ion-item
      button
      routerLink="/account"
      routerLinkActive="active"
      routerDirection="root"
      detail="false"
    >
      <ion-icon
        aria-hidden="true"
        src="../assets/material-symbols/account_circle_FILL0_wght400_GRAD0_opsz24.svg"
        slot="start"
      ></ion-icon>
      <ion-label>{{ 'general.nav_bar.account' | translate }}</ion-label>
    </ion-item>
  </ion-menu-toggle>
</ion-list>
