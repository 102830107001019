import { Injectable } from '@angular/core';
import {
  FindMarketFreightQuery,
  FindMarketFreightQueryHandler,
  FreightMarketResponse,
  SearchAllMarketFreightsQuery,
  SearchAllMarketFreightsQueryHandler,
  SearchMarketFreightsQuery,
  SearchMarketFreightsQueryHandler,
} from '@okcargo/query-processor';
import { Observable, from } from 'rxjs';
import { BaseQueryRepository } from 'src/app/common/infrastructure/query.repository';

@Injectable({
  providedIn: 'root',
})
export class MarketQueryService {
  // private readonly countMarketFreightsQueryHandler: CountMarketFreightsQueryHandler;
  private readonly searchMarketFreightsQueryHandler: SearchMarketFreightsQueryHandler;
  private readonly searchAllMarketFreightsQueryHandler: SearchAllMarketFreightsQueryHandler;
  private readonly findMarketFreightQueryHandler: FindMarketFreightQueryHandler;

  constructor(private readonly queryRepository: BaseQueryRepository) {
    // this.countMarketFreightsQueryHandler = new CountMarketFreightsQueryHandler(queryRepository);
    this.searchMarketFreightsQueryHandler = new SearchMarketFreightsQueryHandler(queryRepository);
    this.searchAllMarketFreightsQueryHandler = new SearchAllMarketFreightsQueryHandler(queryRepository);
    this.findMarketFreightQueryHandler = new FindMarketFreightQueryHandler(queryRepository);
  }

  // public countMarketFreights(query: CountMarketFreightsQuery): Promise<CountResponse> {
  //   return this.countMarketFreightsQueryHandler.handle(query);
  // }

  public searchMarketFreights(query: SearchMarketFreightsQuery): Promise<FreightMarketResponse[]> {
    return this.searchMarketFreightsQueryHandler.handle(query);
  }

  public searchAllMarketFreights(query: SearchAllMarketFreightsQuery): Promise<FreightMarketResponse[]> {
    return this.searchAllMarketFreightsQueryHandler.handle(query);
  }

  public findFreightFromMarket(query: FindMarketFreightQuery): Observable<FreightMarketResponse> {
    return from(this.findMarketFreightQueryHandler.handle(query));
  }
}
