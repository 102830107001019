import { ECapabilities, EMailingPeriodicity, ERoles, IAccount, ICompany, ICostCenter } from '../domain/account.model';

export function transformToIAccount(
  accountResponse: any,
  companyResponse: any, // To know company data, cost centers and email periodicity /// comes from '/services/uaa/api/carriers/mine'
  companyCapabilitiesResponse: any, // Just to know if drivers can see market (available freights)
  companyAsCarrier: any, // Just to know if administrative user can upload/ship freihgts
  account: IAccount,
): IAccount {
  account.capabilities.push(ECapabilities.AUTHENTICATED);

  account.id = accountResponse.id ?? account.id;
  account.firstName = accountResponse.firstName ?? account.firstName;
  account.lastName = accountResponse.lastName ?? account.lastName;
  account.email = accountResponse.email ?? account.email;
  // account.username = response.username ?? account.username; // not in /uaa/api/account response, use email?
  account.username = accountResponse.email ?? account.username; // TODO review
  account.privacyPolicyAccepted = accountResponse.privacyPolicyAccepted ?? account.privacyPolicyAccepted;

  account.lang = accountResponse.langKey.toLowerCase() ?? account.lang;

  account.company = transformToICompany(companyResponse.company);

  if (companyResponse.sendMarketEmail === false) {
    account.emailPeriodicity = EMailingPeriodicity.NEVER;
  } else if (companyResponse.emailPeriodicity) {
    account.emailPeriodicity =
      EMailingPeriodicity[companyResponse.emailPeriodicity as keyof typeof EMailingPeriodicity];
  }

  const authorities = accountResponse.authorities;

  if (Array.isArray(authorities)) {
    if (authorities.includes('ROLE_CARRIER_ADMIN')) {
      account.role = ERoles.ADMINISTRATIVE;
      account.capabilities.push(
        ECapabilities.ADMINISTRATIVE_DOCUMENTS,
        ECapabilities.CRUD_USERS,
        ECapabilities.CRUD_VEHICLES,
        ECapabilities.VIEW_PRICES,
        ECapabilities.VIEW_DRIVERS,
      );
      if (account.company.isMarketAllowed) {
        account.capabilities.push(ECapabilities.VIEW_MARKET);
      }
      if (Array.isArray(companyAsCarrier) && companyAsCarrier.length > 0) {
        account.capabilities.push(ECapabilities.UPLOAD_FREIGHTS);
        account.company.costCenters = transformToICostCenters(companyResponse);
      }
    }
    if (authorities.includes('ROLE_DRIVER')) {
      account.capabilities.push(ECapabilities.INTERACT_WITH_STOPS);

      if (account.role === ERoles.ADMINISTRATIVE) {
        account.role = ERoles.DRIVER_ADMINISTRATIVE;
      } else {
        account.role = ERoles.DRIVER;
        if (
          Array.isArray(companyCapabilitiesResponse) &&
          companyCapabilitiesResponse.includes('MARKET_ALLOWED_TO_DRIVERS')
        ) {
          account.capabilities.push(ECapabilities.VIEW_MARKET);
        }
      }
    }
  }

  return account;
}

function transformToICompany(company: any): ICompany {
  const isInternational = /^[a-zA-Z]{2}/.test(company.taxNumber);
  const isNotES = !/^ES$/i.test(company.taxNumber.substring(0, 2));

  return {
    id: company.id,
    name: company.name,
    taxNumber: company.taxNumber,
    streetAddress: company.streetAddress,
    postalCode: company.postalCode,
    city: company.city,
    stateProvince: company.stateProvince,
    country: company.country,
    phone: company.phone,
    // TODO
    activated: company.activated,
    isInternational: isInternational && isNotES,
    isMarketAllowed: company.reliability === 0 ? false : true,
  };
}

function transformToICostCenters(companyResponse: any): ICostCenter[] {
  const costCenters = companyResponse.company.costCenters.map((costCenter: any) => ({
    id: costCenter.id,
    name: costCenter.name,
  }));

  return costCenters;
}
